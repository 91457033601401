body,
html {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
}
.hide-imp{
    display: none !important;
}

.form-builder {
    background-color: transparent;
    /* Matches the main container background */
    padding: 0;
    width: 100%;
    margin: 0;
    text-align: left;
}

.form-builder h2 {
    text-align: center;
    font-size: 28px;
    margin-bottom: 15px;
    font-weight: bold;
    color: #002060;
    /* Dark blue for headers */
}

.form-builder h4 {
    text-align: center;
    font-size: 18px;
    margin-bottom: 20px;
    color: #002060;
    /* Dark blue for subheaders */
}

.form-section h3 {
    padding: 10px 0;
    margin: 0;
    border-bottom: 1px solid #ddd;
    font-size: 18px;
    font-weight: bold;
    color: #002060;
    /* Dark blue for section headers */
}

.form-section {
    padding-top: 20px;
}

/* Styling form groups */
.form-group {
    margin-top: 10px;
    margin-bottom: 20px;
    padding-right: 2%;
}

/* Styling the labels */
.form-group label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    color: #002060;
    /* Dark blue for labels */
    font-size: 16px;
}

/* Styling the input fields */
.file-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    margin-bottom: 15px;
}

.form-button,.preview-button {
    display: inline-block;
    /* Change to inline-block to allow for width adjustment */
    width: auto;
    /* Set width to auto to fit the content */
    padding: 8px 16px;
    /* Reduce padding to make the button smaller */
    background-color: #3498db;
    /* Red color for the button */
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    /* Slightly reduce the font size */
    margin-top: 10px;
    text-transform: uppercase;
}

.preview-button{
    background-color: rgb(131, 111, 185);
}

/* Styling for status messages */
.status-message {
    margin-top: 10px;
    font-weight: bold;
    font-size: 14px;
}

.status-yellow {
    color: #F29339;
    /* Bootstrap's warning color or any yellow shade */
}

.status-red {
    color: #dc3545;
    /* Bootstrap's danger color or any red shade */
}

.status-green {
    color: #28a745;
    /* Bootstrap's success color or any green shade */
}

/* Styling for error messages */
.error-message {
    margin-top: 5px;
    color: #dc3545;
    font-size: 12px;
}

/* Responsive design adjustments */
@media (max-width: 768px) {
    .form-builder {
        padding: 20px;
        max-width: 100%;
        max-height: 60vh;
    }

    .form-builder h2 {
        font-size: 24px;
    }
}

/* Tooltip container */
.tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

/* Tooltip text */
.tooltip .tooltiptext {
    visibility: hidden;
    max-width: 400px; /* Maximum width to prevent it from getting too large */
    width: auto; /* Allow the width to adjust dynamically */
    background-color: #f7f7f7; /* Slightly off-white background for a softer look */
    color: #333; /* Darker color for readability */
    text-align: left;
    border-radius: 8px; /* More rounded corners for a modern look */
    padding: 5px 10px; /* Extra padding for better spacing */
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15); /* Deeper shadow for a floating effect */
    border: 1px solid #c60b0b; /* Softer border for better visual separation */
    position: fixed;
    z-index: 9999;
    opacity: 0;
    transition: opacity 0.3s ease, transform 0.3s ease; /* Add a slight transformation on appearance */
    white-space: normal;
    word-wrap: break-word;
    left: 10px; /* Prevent tooltip from going off the screen on the left */
    right: 10px; /* Prevent tooltip from going off the screen on the right */
    font-family: 'Arial', sans-serif; /* Ensure a clean, readable font */
    font-size: 16px; /* Standard font size for readability */
    line-height: 1.6; /* Add more spacing between lines */
}

/* Hover effect to make it visible */
.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
    transform: translateY(10px);
    /* Slight upward motion for a smooth appearance */
}

/* Optional: Add a subtle fade-in animation */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Adjust tooltip if it overflows the right side */
.tooltip-right .tooltiptext {
    left: auto;
    right: auto;
}

/* Adjust tooltip if it overflows the bottom */
.tooltip-bottom .tooltiptext {
    bottom: auto;
    top: auto;
}

/* Tooltip icon styling */
.tooltip-icon {
    margin-left: 8px;
    color: #666;
    font-size: 14px;
    cursor: pointer;
}

/* Link icon styling */
.link-icon {
    margin-left: 10px;
    display: inline-flex;
    align-items: center;
    color: #c60b0b;
    font-weight: bold;
    text-decoration: none;
    font-size: 18px;
    transition: background-color 0.3s ease, color 0.3s ease;
    padding: 5px 10px;
    border-radius: 5px;
    background-color: #f0f0f0;
    border: 1px solid #c60b0b;
}

.link-icon:hover {
    background-color: #a00909;
    color: #fff;
}

.link-icon-image {
    margin-left: 5px;
    font-size: 20px;
    /* Increase icon size */
}

/* Optional: Add hover effects to make it more noticeable */
.link-icon:hover .link-icon-image {
    transform: scale(1.2);
    /* Enlarge icon on hover */
}

/* Styling for the document list */
.document-list {
    font-family: 'Arial', sans-serif;
    /* Keep it simple and clean */
    font-size: 12px;
    /* Maintain readability */
    font-weight: 300;
    color: #333;
    /* Neutral color for easy reading */
    line-height: 1.8;
    /* Increased line height for better spacing between lines */
    /* More top and bottom margin for breathing space */
    background-color: #f7f7f7;
    /* Softer background for less harsh contrast */
    padding: 5px;
    /* Increase padding for better spacing inside */
    border-radius: 8px;
    /* More rounded corners for a modern look */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    /* More depth with a bigger shadow */
    border: 1px solid #ccc;
    /* Slightly darker border for better separation */
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    /* Add smooth transitions on hover */
}

/* Optional hover effect to make the document list interactive */
.document-list:hover {
    background-color: #f0f0f0;
    /* Slightly darker background on hover */
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
    /* A deeper shadow for hover */
}

/* Styling for strong text (like the headers) */
.document-list strong {
    color: #002060;
    /* Dark blue for headers */
    font-weight: bold;
}

/* List styling inside the document list */
.document-list ul {
    list-style-type: disc;
    /* Standard bullet points */
    margin-left: 20px;
    padding-left: 10px;
}

/* List items styling */
.document-list li {
    margin-bottom: 8px;
    color: #333;
    /* Text color for list items */
}

/* Optional: Styling for line breaks */
.document-list br {
    margin-bottom: 8px;
}

/* Responsive design adjustments */
@media (max-width: 768px) {
    .document-list {
        font-size: 14px;
        /* Slightly smaller text for mobile */
        padding: 10px;
    }

    .document-list ul {
        margin-left: 15px;
        padding-left: 8px;
    }
}

.button-loader {
    border: 4px solid #f3f3f3;
    /* Light grey */
    border-top: 4px solid #3498db;
    /* Blue */
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
    margin: auto;
    /* Center the loader within its container */
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.form-control-cc {
    width: 20%;
    padding: 8px 5px;
    font-size: 14px;
    margin-bottom: 10px;
    line-height: 1.5;
    border: 2px solid #ddd;
    border-radius: 8px;
    color: #333;
    outline: none;
    transition: all 0.1s ease;
    box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.1);
}
.form-control {
    width: 100%;
    padding: 8px 12px;
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 1.5;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fffffff0;
    color: #333;
    appearance: none; /* Removes the default dropdown arrow for custom styling */
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10"><path fill="%23333" d="M0 0 L10 0 L5 5 Z"/></svg>');
    background-repeat: no-repeat;
    background-position: right 12px center;
    background-size: 10px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}


.form-control:focus {
    border-color: #c60b0b; /* Slightly red color to match the highlighted border */
    outline: none;
    box-shadow: 0px 0px 5px rgba(198, 11, 11, 0.5);
}
.form-control-text {
    width: 50%;
    padding: 8px 12px;
    font-size: 16px;
    margin-bottom: 10px;
    line-height: 1.5;
    border: 2px solid #ddd;
    border-radius: 8px;
    color: #333;
    outline: none;
    transition: all 0.1s ease;
    box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.1);
}
.form-control-date {
    width: 50%;
    padding: 8px 12px;
    font-size: 16px;
    margin-bottom: 10px;
    line-height: 1.5;
    border: 2px solid #ddd;
    border-radius: 8px;
    color: #333;
    outline: none;
    transition: all 0.1s ease;
    box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.1);
}
/* Custom Date Picker Icon */
.form-control-date[type="date"] {
    appearance: none;
    background-color: #9a1515;
    position: relative;
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2025 Fonticons, Inc.--><path d="M128 0c17.7 0 32 14.3 32 32l0 32 128 0 0-32c0-17.7 14.3-32 32-32s32 14.3 32 32l0 32 48 0c26.5 0 48 21.5 48 48l0 48L0 160l0-48C0 85.5 21.5 64 48 64l48 0 0-32c0-17.7 14.3-32 32-32zM0 192l448 0 0 272c0 26.5-21.5 48-48 48L48 512c-26.5 0-48-21.5-48-48L0 192zm64 80l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm128 0l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0zM64 400l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0zm112 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16z"/></svg>') no-repeat right 12px center;
    background-size: 22px;
    /* padding-right: 40px; */
    cursor: pointer;
}



/* Placeholder styles */
.form-control-date::placeholder {
    color: #888;
    opacity: 0.7;
}

/* For better compatibility in Safari */
.form-control-date[type="date"]::-webkit-calendar-picker-indicator {
    opacity: 0;
    cursor: pointer;
}
.button-group {
    display: flex;
    gap: 10px; /* Adjust the space between the buttons */
}

.submit-button,
.clear-button {
    padding: 8px 16px;
    font-size: 14px;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    background-color: #007bff; /* Blue background for buttons */
    color: #fff;
    transition: background-color 0.3s ease;
}

.submit-button:hover,
.clear-button:hover {
    background-color: #0056b3; /* Darker shade on hover */
}

.clear-button {
    background-color: #dc3545; /* Red background for the clear button */
}
.form-group-base{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 50%;
}